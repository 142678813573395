.sanity-modal-container {
  position: fixed;
  width: 100vw !important;
  height: 100vw !important;
  max-width: 100vw !important;
  max-height: 100vw !important;
  
  .tab {
    .close-button {
      color: #000;
      width: 20px;
      height: 20px;
      float: right;
      cursor: pointer;
    }
  }
  .modal-content {
    overflow: hidden;
    width: 80vw;
    height: 95vh;
    left: 10vw;
    top: 0vw;
    @media (max-width: 768px) {
      height: 80vh;
    }
    .single-modal-body {
      padding: 0;
      overflow: hidden;
      height: 100%;

      .sanity-window {
        min-height: 100% !important;
      }

      .thumbnail-container {
        height: 250px;

        .activity-thumbnail {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .content-container {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 10px;

        .button-col {
          .activity-location {
            border-radius: 4px;
            background: var(--background);
            color: var(--foreground);
            font-size: 12px;
            border: none;
            font-family: 'Quicksand', sans-serif;
            padding-left: 15px;
            padding-right: 15px;
            height: 25px;
          }
        }

        .date-col {
          .date-specific {
            font-family: 'Quicksand', sans-serif;
            font-size: 12px;
            color: #525252;
            margin-top: 5px;
          }
        }

        .activity-title {
          display: block;
          font-family: 'Quicksand', sans-serif;
          font-size: 18px;
          color: #525252;
          font-weight: 500;
          margin-top: 5px;
        }

        .activity-description {
          font-family: 'Quicksand', sans-serif;
          font-size: 12px;
          color: gray;
          font-weight: 400;
        }
      }
    }
  }
}
