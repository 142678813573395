.report-modal-container {
    .report-modal-body {
      .report-info-container {
        .report-title {
          color: #6d6d6d;
          font-family: 'Quicksand', sans-serif;
          font-size: 20px;
          margin-top: 10px;
        }
        .report-description {
          font-family: 'Quicksand', sans-serif;
          font-size: 14px;
          color: gray;
          font-weight: 400;
          margin-top: 20px;
        }
      }
      .configuration-settings-container {
        .single-configuration-container {
          margin-bottom: 20px;
          position: relative;
  
          .input-label {
            display: block;
            width: 100%;
            font-family: 'Quicksand', sans-serif;
            font-size: 14px;
            color: #525252;
            font-weight: 500;
            margin-top: 20px;
          }
          .input-field, select {
            width: 100%;
            border: none;
            background: #f3f3f3;
            height: 40px;
            border-radius: 4px;
            padding-left: 15px;
            font-family: 'Quicksand', sans-serif;
            font-size: 12px;
            color: gray;
          }

          .input-area{
            padding:10px;
            width: 100%;
            border: none;
            background: #f3f3f3;
            border-radius: 4px;
            padding-left: 15px;
            font-family: 'Quicksand', sans-serif;
            font-size: 12px;
            color: gray;
          }
  
          .search-results-container {
            position: absolute;
            border: none;
            background: white;
            width: 100%;
            margin-top: 10px;
            z-index: 100;
            border-radius: 4px;
            max-height: 90px;
            padding-top: 5px;
            padding-bottom: 5px;
            overflow-y: scroll;
            box-shadow: 0 0 15px #ececec;
            -webkit-animation: slide-in-fwd-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
            animation: slide-in-fwd-top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  
            .single-result-container {
              padding-left: 15px;
              padding-right: 15px;
              padding-top: 5px;
              padding-bottom: 5px;
  
              p {
                font-family: 'Quicksand', sans-serif;
                font-size: 12px;
                color: gray;
                display: block;
                position: relative;
                padding: 0;
                margin: 0;
              }
  
              &:hover {
                cursor: pointer;
                p {
                  font-weight: 500;
                  color: #3e7671;
                }
              }
            }
          }
        }
  
        .all-options-container {
          .options-label {
            display: block;
            width: 100%;
            font-family: 'Quicksand', sans-serif;
            font-size: 14px;
            color: #525252;
            font-weight: 500;
            margin-top: 20px;
          }
          .options-list {
            margin-top: 10px;
            .single-option-container {
              display: inline-block;
              background: #f3f3f3;
              border-radius: 4px;
              position: relative;
              padding-left: 25px;
              padding-right: 40px;
              padding-top: 12px;
              padding-bottom: 5px;
              margin-right: 10px;
              margin-bottom: 10px;
              transition: 0.5s;
  
              .option-title {
                font-family: 'Quicksand', sans-serif;
                font-size: 12px;
                text-align: center;
                color: #6d6d6d;
                font-weight: 500;
  
                .option-star {
                  color: #3e7671;
                  margin-right: 10px;
                  font-size: 12px;
                }
              }
  
              .remove-button {
                position: absolute;
                top: 10px;
                right: 5px;
                font-size: 12px;
                border: none;
                background-color: transparent;
                color: grey;
              }
  
              &:hover {
                cursor: pointer;
                background-color: #3e7671;
                border-color: #3e7671;
  
                .option-title {
                  font-family: 'Quicksand', sans-serif;
                  font-size: 12px;
                  text-align: center;
                  color: white;
  
                  .option-star {
                    color: white;
                    margin-right: 10px;
                  }
                }
  
                .remove-button {
                  color: white;
                }
              }
            }
          }
        }
      }
    }

    .close-button{
        border: none;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 4px;
        background: #f3f3f3;
        color: gray;
        margin-right: 10px;
        font-size: 14px;
        font-family: 'Quicksand', sans-serif;
    }

    .report-button{
        border: none;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 4px;
        background: #3e7671;
        background: linear-gradient(to right, #3e7671, #306a87);
        color: white;
        font-size: 14px;
        font-family: 'Quicksand', sans-serif;
    }
  }
  
  @-webkit-keyframes slide-in-fwd-top {
    0% {
      -webkit-transform: translateZ(-1400px) translateY(-20px);
      transform: translateZ(-1400px) translateY(-20px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0) translateY(0);
      transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-fwd-top {
    0% {
      -webkit-transform: translateZ(-1400px) translateY(-20px);
      transform: translateZ(-1400px) translateY(-20px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0) translateY(0);
      transform: translateZ(0) translateY(0);
      opacity: 1;
    }
  }
  