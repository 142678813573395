.cookie-policy-section{
    .inner-cookie-policy-container{
        max-width: 1200px;
        margin: 0 auto;
        .content-group{
            margin-bottom: 50px;
            h1{
                color: #3a3a3b !important;
                font-family: 'Ubuntu', sans-serif;
                font-size: 30px;
                font-weight: 500;
                margin-top: 10px;
            }

            h3{
                color: #3a3a3b !important;
                font-family: 'Ubuntu', sans-serif;
                font-size: 24px;
            }

            p, li{
                font-family: 'Ubuntu', sans-serif !important;
                font-size: 14px;
                color: #888888;
                font-weight: 400 !important;
                font-style: normal !important;
                padding-bottom: 0px;
            }
        }
    }
}