// Colors
$background: #ccc;
$background-gray: #f6f6f6;
$background-white: #fff;
$text-black: #000;
$text-gray: #3e3e3e;
$text-white: #fff;
$text-light-gray: #3e3e3e;
$accent-primary: #13554f;
// Fonts
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
}
