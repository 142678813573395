.single-content-section {
  padding-bottom: 50px;
  width: 100%;
  height: 90vh;

  .inner-single-content-container {
    padding: 0;
    max-width: 100%;
    margin: 0;

    .single-content-container {
      .single-title {
        font-family: 'Open Sans', serif;
        color: var(--foreground);
        font-weight: 400;
      }
      h2 {
        font-weight: 100;
      }
      .embed-iframe {
        width: 100%;
        height: 90vh;
      }
    }
  }
}

.iframe-content-wrapper {
  height: 90vh;
}