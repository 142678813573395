@import '../../styles/mixins.scss';

.header-container {
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: transparent;
  padding-top: 18px;
  transition: height 0.2s ease-in, padding-top 0.2s ease-in, background-color 0s;
  &.scrolled {
    background-color: $background-white;
    padding: 0;
    height: 70px;
    transition: height 0.2s ease-in, padding-top 0.2s ease-in, background-color 0.2s ease-in;
    border-bottom: 1px solid #ccc;
    z-index: 150;
    .content {
      position: relative;
      .navigation {
        li {
          a {
            color: #806969;
            &:hover {
              color: #6d6d6d;
              border-bottom-width: 3px;
              border-bottom-color: #3e7671;
            }
          }
        }
      }
    }
  }
  .content {
    margin: 5px 0;
    position: relative;
    .logo {
      img {
        width: 25px;
        margin-top: 10px;
      }
    }
    .navigation {
      line-height: 60px;
      color: $text-white;
      ul {
        margin: 0;
        li {
          display: inline-block;
          font-size: 16px;
          margin: 0 6px;
          a {
            color: $text-white;
            font-family: 'Quicksand', sans-serif;
            text-decoration: none;
            border-bottom: 1px solid transparent;
            padding-bottom: 2px;
            font-size: 14px;
            font-weight: 500;
            &:hover {
              border-bottom: 1px solid $text-white;
            }
          }
        }
      }
    }
    .login {
      button {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #3e7671;
        background: -webkit-linear-gradient(to right, #3e7671, #306a87);
        background: linear-gradient(to right, #3e7671, #306a87);
        font-family: 'Quicksand', sans-serif;
        font-weight: 500;
        border-radius: 4px;
        border: none;
        padding: 10px 24px;
        font-size: 14px;
        color: white;
        a {
          color: $text-white;
        }
      }
    }
  }
}
