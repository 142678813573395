@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~c3/c3.min.css';
@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

@import 'components/kit/vendors/antd/style';
@import 'components/kit/vendors/react-sortable-tree/style';

@import 'components/kit/core/style';
@import 'components/cleanui/styles/style';

// very important, dont delete it
body {
  .ant-notification {
    z-index: 9999 !important;
  }
  a {
    color: var(--foreground);
  }
  a:hover {
    color: inherit;
  }
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 26px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 16px;
  }

  iframe {
    height: 100%;
  }

  // todo support primary when needed
  .riot-btn.secondary {
    &[disabled] {
      background-color: var(--shade);
      cursor: not-allowed;
      border: none;
      outline: none;
		  opacity: .7;
    }

    border-radius: 25px;
    outline: none;
    padding: 5px 18px;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    font-weight: 400;
    place-items: center;
    place-content: center;
    display: flex;
    gap: .5em;

    background-color: var(--background);
    border: 2px solid var(--flavor);
    color: var(--foreground);

    &[active] {
      background-color: var(--flavor-shade);
      border-color: var(--flavor-shade);
      color: var(--background);
    }

    &:focus:not([disabled]) {
      background-color: var(--flavor);
      border-color: var(--flavor);
      color: var(--background);
    }

    &:hover:not([disabled]) {
      background-color: var(--flavor);
      border-color: var(--flavor);
      color: var(--background);
    }

    &.round {
      border-radius: 50%;
      width: 35px;
      height: 35px;
      display: grid;
      padding: 0;
      place-items: center;
      place-content: center;
    }
  }
}
